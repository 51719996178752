import { Injectable } from '@angular/core';

import { List } from 'linqts';
import { TranslateService } from '@ngx-translate/core';

import { LocalStorageServices } from './local.storage.services';

@Injectable({
  providedIn: 'root'
})
export class SettingsServices {

  public currentLanguage: string;
  public supportedlanguages: Array<any> = [{ name: "English", value: "en" }, { name: "Français", value: "fr" }];

  constructor(
    private localStorageServices: LocalStorageServices,
    private translateService: TranslateService) {
  }

  public initialize(): void {
    console.log('SettingsServices.initialize : Navigator language', window.navigator.language);
    const values = new List(this.supportedlanguages).Select(sl => sl.value).ToArray();
    this.translateService.addLangs(values);
    this.currentLanguage = this.getLanguage();
    if (this.currentLanguage === undefined || this.currentLanguage === null) {
      const parts = window.navigator.language.split('-')[0];
      const currentnavigatorlanguage = parts[0];
      if (values.includes(currentnavigatorlanguage)) {
        console.log('SettingsServices.initialize : Set navigator language', currentnavigatorlanguage);
        this.translateService.setDefaultLang(currentnavigatorlanguage);
        this.currentLanguage = currentnavigatorlanguage;
      } else {
        console.log('SettingsServices.initialize : Set default language', values[0]);
        this.translateService.setDefaultLang(values[0]);
        this.currentLanguage = values[0];
      }
    } else {
      console.log('SettingsServices.initialize : Set setting language', this.currentLanguage);
      this.translateService.setDefaultLang(this.currentLanguage);
    }
  }

  public getLanguage(): string {
    return this.localStorageServices.get('language');
  }

  public setLanguage(languageValue: string) {
    this.localStorageServices.set('language', languageValue);
    this.currentLanguage = languageValue;
    this.translateService.setDefaultLang(this.currentLanguage);
  }
}
