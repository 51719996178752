import { Component, ElementRef, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { environment } from 'src/environments/environment';

import { TranslateService } from '@ngx-translate/core';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';

import { PaymentsServices } from 'src/app/services/payments/payments.services';
import { UsersServices } from 'src/app/services/users/users.services';
import { EmailsServices } from 'src/app/services/emails/emails.services';

import { Bill } from 'src/app/models/bill.model';
import { User } from 'src/app/models/user.model';
import { LicenceType } from 'src/app/models/licence.type.enum';
import { Payment } from 'src/app/models/payment.model';

@Component({
  selector: 'app-bill',
  templateUrl: './bill.component.html',
  styleUrls: ['./bill.component.scss']
})
export class BillComponent {

  get LicenceType() {
    return LicenceType;
  }
  
  public isCellPhone: boolean = false;
  public userId: string;
  public paymentId: string;
  public user: User;
  public payment: Payment;
  public bill: Bill = new Bill();

  constructor(
    private router: Router,
    private renderer: Renderer2, 
    private elementRef: ElementRef,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private usersServices: UsersServices,
    private paymentsServices: PaymentsServices,
    private emailsServices: EmailsServices
  ) {
    this.activatedRoute.params.subscribe(params => {
      console.log('BillComponent.constructor : Retrieved params', params);
      this.paymentId = params['paymentid'];
      this.userId = params['userid'];
    });
  }

  async ngOnInit(): Promise<void> {
    this.detectScreenWidth();

    await this.paymentsServices.get(this.paymentId).then((data: Payment) => {
      this.payment = data;
    });

    await this.usersServices.get(this.userId).then((data: User) => {
      this.user = data;
    });

    this.bill.amount = this.payment.amount / 100;
    this.bill.amountWithTaxes = this.payment.amount / 100;
    this.bill.amountWithoutTaxes = this.computeWithoutTaxes(this.payment.amount / 100);
    this.bill.number = this.payment._id;
    this.bill.product = this.payment.description;
    this.bill.modification = new Date();
    this.bill.creation = new Date();
  }

  public done(): void {
    let data = document.getElementById('bill');
    let table = document.getElementById('bill-table');
    table.setAttribute('border', '1');

    const doc = new jsPDF('p', 'mm', 'a4');
    const margin = 30;
    const logoPath = './../assets/images/logo_gv_black.png';
    const billFileName = 'GlobalVesta_Bill_' + this.bill.number + '.pdf';

    const img = new Image();
    img.src = logoPath;
    let pdfDataUrl = null;
    img.onload = () => {
      console.log(`L'image ${logoPath} existe.`);

      // Ajoutez d'abord le logo
      doc.addImage(logoPath, 'PNG', 210 / 2, 10, 20, 20);

      html2canvas(data).then(canvas => {
        console.log('BillComponent.done : canvas', canvas);

        const contentDataUrl = canvas.toDataURL('image/png');
        const pageWidth = 180;
        const pageHeight = 45;
        const imgWidth = pageWidth - 2 * margin;
        const imgHeight = ((pageHeight * imgWidth) / pageWidth) - 2 * margin;

        // Ajoutez le contenu de la page après le logo
        doc.addImage(contentDataUrl, 'PNG', margin / 2, 100, pageWidth, pageHeight);
        doc.save(billFileName);

        pdfDataUrl = doc.output('datauristring');

        table.removeAttribute('border');
      });
    };

    img.onerror = () => {
      console.error(`L'image ${logoPath} n'existe pas.`);
    };

    // Payer EmailJS pour avoir plus de template 
    const message = this.translateService.instant('bill.mailmessage');
    this.emailsServices.send(this.user.email, message, 'Payment confirmed', 'template_k56120a').then(data => {
      console.log('BillComponent.done : Email sent');
    });
    window.open(environment.vestapediaUrl, '_blank');
  }

  private computeWithoutTaxes(price: number): number {
    const tps = environment.tps / 100;
    const tvq = environment.tvq / 100;
    return price / (1 + tps + tvq);
  }

  private detectScreenWidth() {
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    if (screenWidth <= 768) {
      // Le code à exécuter lorsque l'écran est de petite taille (téléphone portable)
      // Par exemple, vous pouvez ajouter ou supprimer des éléments DOM spécifiques
      // en fonction de la largeur de l'écran.
      this.isCellPhone = true;
      this.renderer.addClass(this.elementRef.nativeElement, 'mobile-screen');
    } else {
      // Le code à exécuter lorsque l'écran est de grande taille (desktop)
      // Par exemple, vous pouvez réinitialiser les modifications apportées pour les mobiles.
      this.renderer.removeClass(this.elementRef.nativeElement, 'mobile-screen');
    }
  }

}
