import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { MaterialModule } from './material.module';

import { HomeComponent } from './pages/home/home.component';
import { UserInformationsComponent } from './pages/userinformations/userinformations.component';
import { ConfirmemailComponent } from './pages/confirmemail/confirmemail.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { BillComponent } from './pages/bill/bill.component';
import { ProductsPopupComponent } from './components/products-popup/products-popup.component';
import { WelcomePopupComponent } from './components/welcome-popup/welcome-popup.component';
import { TeamsPopupComponent } from './components/teams-popup/teams-popup.component';
import { ContactPopupComponent } from './components/contact-popup/contact-popup.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    UserInformationsComponent,
    ConfirmemailComponent,
    PaymentComponent,
    BillComponent,
    
    ProductsPopupComponent,
    WelcomePopupComponent,
    TeamsPopupComponent,
    ContactPopupComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,

    AppRoutingModule,
    MaterialModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
    }),
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}