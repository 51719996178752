import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'src/environments/environment';

import { RealmTokenServices } from '../commons/realm.token.services';

import { Payment } from 'src/app/models/payment.model';

@Injectable({
  providedIn: 'root'
})
export class PaymentsServices {

  constructor(
    private httpClient: HttpClient,
    private realmTokenServices: RealmTokenServices) { }

    public async get(paymentId: string): Promise<Payment> {
      console.log('PaymentsServices.get: Get the payment', paymentId);

      return new Promise<Payment>(async (resolve, reject) => {

        let token = null;
        await this.realmTokenServices.getToken().then(data => {
          console.log('PaymentsServices.get : Token', data);
          token = data;
        });
  
        const findOneUrl = `${environment.mongoapi.apiFindOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;
  
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        
        await this.httpClient.post<Payment>(findOneUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `${environment.database.prefix}payments`, filter: { '_id': { $eq: { $oid: paymentId } } } }, httpOptions).toPromise().then((data: any) => {
          console.log('PaymentsServices.get : Payment', data);
          resolve(data.document);
        });
  
      });
    } 
  
  public async addPayment(payment: Payment): Promise<Payment> {
    console.log('PaymentsServices.addPayment: Add a payment', payment);
    
    const paymentData = { 
      stripeToken: payment.stripeToken, 
      amount: payment.amount, 
      description: payment.description, 
      userId: payment.userId,
      status: payment.status,
      method: payment.method,
      modification: new Date(),
      creation: new Date() 
    };

    return new Promise<Payment>(async (resolve, reject) => {

      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('PaymentsServices.addPayment : Token', data);
        token = data;
      });

      const insertUrl = `${environment.mongoapi.apiInsertOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
      
      await this.httpClient.post<Payment>(insertUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `${environment.database.prefix}payments`, document: paymentData }, httpOptions).toPromise().then((data: any) => {
        console.log('PaymentsServices.addPayment : Payment added', data);
        payment._id = data.insertedId;
        resolve(payment);
      });

    });

  } 

}
