import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LicenceType } from 'src/app/models/licence.type.enum';

@Component({
  selector: 'app-products-popup',
  templateUrl: './products-popup.component.html',
  styleUrls: ['./products-popup.component.scss']
})
export class ProductsPopupComponent {

  get LicenceType() {
    return LicenceType;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ProductsPopupComponent>) {
    console.log('ProductsPopupComponent.constructor : Start to open the popup', data);
  }

  close(): void {
    this.dialogRef.close();
  }
}