import { Injectable } from '@angular/core';

declare var google: any;

@Injectable({
  providedIn: 'root'
})
export class GeocodingServices {

  constructor() { }

  public getLocation(): Promise<any> {
    console.log('GeocodingServices.getLocation : Start to get location');

    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function(position) {
          console.log('GeocodingServices.getLocation : Position found', position);
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          const geocoder = new google.maps.Geocoder();
          const latlng = new google.maps.LatLng(latitude, longitude);
          
          geocoder.geocode({ location: latlng }, function(results, status) {
            if (status === 'OK') {
              if (results[0]) {
                const addressComponents = results[0].address_components;
                let city, country;
                for (const component of addressComponents) {
                  if (component.types.includes('locality')) {
                    city = component.long_name;
                  } else if (component.types.includes('country')) {
                    country = component.long_name;
                  }
                }

                const locationData = {
                  city: city,
                  country: country
                };
                console.log(`GeocodingServices.getLocation : City: ${city}, Country: ${country}`);
                resolve(locationData);
              } else {
                reject('GeocodingServices.getLocation : No results found');
              }
            } else {
              reject(`GeocodingServices.getLocation : Geocoding failed with status ${status}`);
            }
          });
        });
      } else {
        console.log('GeocodingServices.getLocation : Unable to use location');
        reject('GeocodingServices.getLocation : Unable to use location');
      }
    });
  }
}
