<mat-card class="popup-card flex">
    <mat-card-content class="scrollable">
        <div class="p-sm-4 p-2">
            <div class="row mb-3">
                <div class="col-12">
                    <h2 class="tm-page-title">Background of Our Team</h2>        
                </div>
            </div>
            <div class="row tm-reverse-sm">
                <div class="col-sm-12 col-md-8 col-lg-8 col-xl-8">
                    <p class="mb-4">When the world have made an interior designer and a software developer face to face uncommon circonstances.</p>
                    <p class="mb-4">We came up with an idea for the industry of interior design and architects to make a platform that would make it easier, more productive and less time consuming in research for furnitures and materials,</p>
                    <p class="mb-5">which will give you more time for creating the dream living of your clients.</p>       
                </div>                                                
                <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-lg-0 mb-sm-4 mb-4">
                    <img src="assets/images/team.jpg" class="img-fluid">
                </div>
            </div>
            <div class="row mt-5">
                <a class="btn btn-primary tm-btn-submit" (click)="close()">Close</a>
            </div>
        </div>
    </mat-card-content>
  </mat-card>
  