export const environment = {
  production: true,
  database: {
    prefix: 'prd-'
  },
  stripe: {
    authenticationUrl: 'https://api.stripe.com/v1/tokens',
    paymentUrl: 'https://api.stripe.com/v1/charges',
    publicKey: '',
    secretKey: ''
  },
  tps: 5,
  tvq: 9.975,
  vestapediaUrl: 'https://vestapedia.com',
  globalvestaEmail: '',
  emailJs: {
    userId: 'FYl-fADnpRoACIUzq',
    serviceId: 'service_l8842g5'
  },
  mongoapi: {
    appId: 'data-buwvj',
    userApplicationKey: 'NzXtroMATixD3hRgVD4RfzjbRSo2K5dahUq5kN2SySQiC0IWmM718ZXFyLv8KYnY',
    authenticationUrl: 'https://realm.mongodb.com/api/client/v2.0/app',
    applicationKey: 'yUobeonsiO9xTfDqlFdtRR1047nVGA5WoLCmt0noeiiIGZ31ViIRQdLReD2PtZ4s',
    apiFindUrl: 'https://us-east-2.aws.data.mongodb-api.com/app/data-buwvj/endpoint/data/v1/action/find',
    apiFindOneUrl: 'https://us-east-2.aws.data.mongodb-api.com/app/data-buwvj/endpoint/data/v1/action/findOne',
    apiInsertOneUrl: 'https://us-east-2.aws.data.mongodb-api.com/app/data-buwvj/endpoint/data/v1/action/insertOne',
    apiUpdateOneUrl: 'https://us-east-2.aws.data.mongodb-api.com/app/data-buwvj/endpoint/data/v1/action/updateOne',
    dataSource: 'Cluster0',
    database: 'Cluster0',
  }
};

