import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'src/environments/environment';

import * as CryptoJS from 'crypto-js';

import { RealmTokenServices } from '../commons/realm.token.services';

import { User } from '../../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UsersServices {

  constructor(
    private httpClient: HttpClient,
    private realmTokenServices: RealmTokenServices) { }

  public async authenticate(email: string, password: string): Promise<User> {
    console.log('UsersServices.authenticate: Start to get user by email', email);

    return new Promise<User>(async (resolve, reject) => {

        let token = null;
        await this.realmTokenServices.getToken().then(data => {
          console.log('UsersServices.authenticate : Token', data);
          token = data;
        });

        const findUrl = `${environment.mongoapi.apiFindOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        const hash = CryptoJS.SHA256(`${User.prefix_salt}${password}${User.suffix_salt}`);
        password = hash.toString(CryptoJS.enc.Hex);
        
        await this.httpClient.post<User>(findUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `${environment.database.prefix}users`, filter: { 'email': { $eq: email }, 'password': { $eq: password } } }, httpOptions).toPromise().then((data: any) => {
          console.log('UsersServices.authenticate : Items', data);
          resolve(data.document);
        });
      });
  }

  public async get(userId: string): Promise<User> {
    console.log('UsersServices.get: Start to get user by id', userId);

    return new Promise<User>(async (resolve, reject) => {

      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('UsersServices.get : Token', data);
        token = data;
      });

      const findOneUrl = `${environment.mongoapi.apiFindOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
      
      await this.httpClient.post<User>(findOneUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `${environment.database.prefix}users`, filter: { '_id': { $eq: { $oid: userId } } } }, httpOptions).toPromise().then((data: any) => {
        console.log('UsersServices.get : User', data);
        resolve(data.document);
      });

    });
  }

  public async addUser(user: User): Promise<User> {
    console.log('UsersServices.addUser: Add an user', user);
    
    const hash = CryptoJS.SHA256(`${User.prefix_salt}${user.password}${User.suffix_salt}`);
    user.password = hash.toString(CryptoJS.enc.Hex);

    const userData = { 
      firstname: user.firstname, 
      lastname: user.lastname, 
      email: user.email, 
      password: user.password,
      mailcodeconfirmation: user.mailcodeconfirmation,
      ismailactive: false,
      language: user.language,
      city: user.city,
      country: user.country,
      modification: new Date(),
      creation: new Date()
    };

    return new Promise<User>(async (resolve, reject) => {

      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('UsersServices.addUser : Token', data);
        token = data;
      });

      const findOneUrl = `${environment.mongoapi.apiFindOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;
      const insertUrl = `${environment.mongoapi.apiInsertOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };

      await this.httpClient.post<User>(findOneUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `${environment.database.prefix}users`, filter: { 'email': { $eq: user.email } } }, httpOptions).toPromise().then((data: any) => {
        console.log('UsersServices.get : User', data);
        if(data.document === null || data.document === undefined) {
          console.log('UsersServices.addUser : User does not exists');
        } else {
          reject(403);
          throw new Error('UsersServices.addUser : Email already exists');
        }
      });
      
      await this.httpClient.post<User>(insertUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `${environment.database.prefix}users`, document: userData }, httpOptions).toPromise().then((data: any) => {
        console.log('UsersServices.addUser : User added', data);
        user._id = data.insertedId;
        resolve(user);
      });
    });
  }  

  public async updateUser(user: User): Promise<User> {
    console.log('UsersServices.updateUser: Update an user', user);
    return new Promise<User>(async (resolve, reject) => {

      let token = null;
      await this.realmTokenServices.getToken().then(data => {
        console.log('UsersServices.updateUser : Token', data);
        token = data;
      });

      const updateUrl = `${environment.mongoapi.apiUpdateOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
      
      await this.httpClient.post<User>(updateUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `${environment.database.prefix}users`, filter: { '_id': { $eq: { $oid: user._id } } }, update: { $set: { 'ismailactive': user.ismailactive } } }, httpOptions).toPromise().then((data: any) => {
        console.log('UsersServices.updateUser : User updated', data);
        if(data.matchedCount > 0) {
          resolve(user);
        } else {
          reject(data);
        }
      });

    });
  }  

}
