<div class="row form-group" *ngIf="isCellPhone === true">
    <div class="login-logo-cellphone d-flex justify-content-center">
      <img src="assets/images/logo_gv.png" alt="logo" class="login-logo-image-cellphone" />
    </div>
  </div>
  
  <div class="row form-group">
      <div class="col-md-5" *ngIf="isCellPhone === false">
        <div class="login-logo">
          <img src="assets/images/logo_gv.png" alt="logo" class="login-logo-image" />
        </div>
      </div>
      <div class="col-md-7">
            <section id="bill" class="about section-bg" style="color: black; padding:20px;">
                <h2>{{ 'bill.title' | translate }}</h2>
                <h3>{{ 'bill.date' | translate }} : {{ bill.creation | date:'yyyy-MM-dd' }}</h3>
                
                <table class="table table-responsive" id="bill-table">
                    <tr>
                        <th>{{ 'bill.number' | translate }}</th>
                        <th>{{ 'bill.product' | translate }}</th>
                        <th>{{ 'bill.amount' | translate }} (USD)</th>
                    </tr>
                    <tr>
                        <td>{{ bill.number }}</td>
                        <td>{{ bill.product }}</td>
                        <td>{{ bill.amount }} $</td>
                    </tr>
                </table>
            </section>
            <div class="form-group mt-5">
                <button type="submit" class="btn btn-primary" #buttonSubmit (click)="done()">{{ 'bill.done' | translate }}</button>
            </div>
      </div>
    </div>