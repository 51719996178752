import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { UserInformationsComponent } from './pages/userinformations/userinformations.component';
import { ConfirmemailComponent } from './pages/confirmemail/confirmemail.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { BillComponent } from './pages/bill/bill.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'userinformations/:licencetype', component: UserInformationsComponent },
  { path: 'confirmemail/:userid/:licencetype', component: ConfirmemailComponent },
  { path: 'payment/:userid/:licencetype', component: PaymentComponent },
  { path: 'payment/:striptoken', component: PaymentComponent },
  { path: 'bill/:userid/:paymentid', component: BillComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
