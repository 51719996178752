import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StringsServices {

  constructor() { }

  public generateConfirmationEmailCode(): string {
    console.log('StringsServices.generateConfirmationEmailCode : Start to generate a code');
    const data = Math.floor(100000 + Math.random() * 900000).toString();
    console.log('StringsServices.generateConfirmationEmailCode : Generated code', data);
    return data;
  }

}
