<div class="row form-group" *ngIf="isCellPhone === true">
  <div class="login-logo-cellphone d-flex justify-content-center">
    <img src="assets/images/logo_gv.png" alt="logo" class="login-logo-image-cellphone" />
  </div>
</div>

<div class="row form-group">
    <div class="col-md-5" *ngIf="isCellPhone === false">
      <div class="login-logo">
        <img src="assets/images/logo_gv.png" alt="logo" class="login-logo-image" />
      </div>
    </div>
    <div class="col-md-7">
      <section id="payment" class="row about section-bg">
<ul class="payment-types">
  <li class="paymenttype pp unselected-left">
    <div class="box">
    <form>
      <div class="form-content">
        <p><strong>About</strong></p>
        <p>This is a basic concept for a payment, checkout process. The user can select between the different payment types, each type has a seperate form to fill in the required data for the transaction. What I tried to provide:</p> 
      <ul>
        <li>A quick and easy way to fill the required form fields.</li>
        <li>Mobile friendly keyboard display</li>
        <li>Disabling auto-correction and spellcheck for names</li>
        <li>Avoid dropdown fields for a better UX.</li>
      </ul>     
      </div>
    </form>
    </div>
  </li>
  <li class="paymenttype selected cc">
    <div class="box">
    <header>
      <div class="card" id="cc-card">
        <div class="flipper">
          <div class="front">
            <div class="shine"></div>
            <div class="shadow"></div>
            <div class="card-bg">
              <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/513985/cc-front-bg.png" />
            </div>
            <div class="card-content">
              <div class="credit-card-type"></div>
              <div class="card-number">
                <span *ngIf="cardNumber">{{ cardNumber }}</span>
                <span *ngIf="!cardNumber">1234 1234 1234 1234</span>
              </div>
              <div class="card-holder">
                <em>Card holder</em>
                <span *ngIf="cardName">{{ cardName }}</span>
                <span *ngIf="!cardName">Your Name</span>
              </div>
              <div class="validuntil">
                <em>Expire</em>
                <div class="e-month">
                  <span *ngIf="cardExpireMonth">{{ cardExpireMonth }}</span>
                    <span *ngIf="!cardExpireMonth">
                      MM
                    </span>
                </div>
                <div class="e-divider">
                     <span>
                      /
                    </span>
                    <span>
                      /
                    </span>
                </div>
                <div class="e-year">
                  <span *ngIf="cardExpireYear">{{ cardExpireYear }}</span>
                    <span *ngIf="!cardExpireYear">
                      YY
                    </span>
                </div>
                
              </div>
            </div>
          </div>

          <div class="back">
            <div class="shine"></div>
            <div class="shadow"></div>
            <div class="card-bg">
             <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/513985/cc-back-bg-new.png" />
            </div>
            <div class="ccv">
              <em>CCV Number</em>
              <strong></strong>
            </div>
            <div class="card-content">
              <div class="card-number">
                <span>4111 1111 1111 1111</span>
                <span>4111 1111 1111 1111</span>
              </div>
              <div class="card-holder">
                <span>Your Name</span>
                <span>Your Name</span>
              </div>
              <div class="validuntil">
                <span>
                  <strong class="e-month">MM</strong> /                 <strong class="e-year">YY</strong>
                </span>
                <span>
                  <strong class="e-month">MM</strong> /
                  <strong class="e-year">YY</strong>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

    <form>
      <div class="form-content">
        <div class="field">
          <mat-form-field class="full-width">
            <mat-label>Card number</mat-label>
            <input matInput type="tel" class="form-control-light" id="cardnumber" name="cardnumber" maxlength="19" [(ngModel)]="cardNumber" (input)="onCreditCardNumberChanged()">
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field class="full-width">
            <mat-label>Card holder (Name on card)</mat-label>
            <input matInput type="text" class="form-control-light" id="cardholder" name="cardholder" maxlength="25" [(ngModel)]="cardName" (input)="onCreditCardNameChanged()">
          </mat-form-field>
        </div>
        <div class="field-group">
          <div class="col-50">
            <label for="expires-month">Expire (Valid until)</label>   
            <div class="field expire-date">
              <div class="row">
                <div class="col-4">
                  <mat-form-field class="full-width">
                    <mat-label>MM</mat-label>
                    <input matInput type="tel" class="form-control-light" id="expires-month" name="expires-month" placeholder="MM" allowed-pattern="[0-9]" maxlength="2" [(ngModel)]="cardExpireMonth" (input)="onCreditCardExpireMonthChanged()">
                  </mat-form-field>
                </div>
                <div class="col-1" style="display: flex;align-items: center;">/</div>
                <div class="col-4">
                  <mat-form-field class="full-width">
                    <mat-label>YY</mat-label>
                    <input matInput type="tel" class="form-control-light" id="expires-year" name="expires-year" placeholder="YY" allowed-pattern="[0-9]" maxlength="2" [(ngModel)]="cardExpireYear" (input)="onCreditCardExpireYearChanged()">
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <div class="col-50">
            <div class="field ccv">
              <mat-form-field class="full-width">
                <mat-label>CCV</mat-label>
                <input matInput type="tel" class="form-control-light" id="ccv" name="ccv" maxlength="3" [(ngModel)]="cardCCV" (input)="onCreditCardCcvChanged()">
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="btn-wrap">
          <div class="form-group mt-5">
            <button class="btn btn-primary btn-buy" (click)="processPayment()">{{ 'payment.pay' | translate }} {{ licenceType / 100 }} $ (USD)</button>
          </div>
        </div>
      </div>
    </form>
    </div>
  </li>
  
</ul>

      </section>
    </div>
  </div>