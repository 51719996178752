import { Entity } from "./entity.model";

export class Payment extends Entity {
  stripeToken: any;
  amount: number;
  description: string;
  userId: string;
  status: string;
  method: string;
}
