import { Component, ElementRef, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

import { LocalStorageServices } from 'src/app/services/commons/local.storage.services';
import { SettingsServices } from 'src/app/services/commons/settings.services';
import { UsersServices } from 'src/app/services/users/users.services';
import { EmailsServices } from 'src/app/services/emails/emails.services';
import { StringsServices } from 'src/app/services/commons/string.services';
import { GeocodingServices } from 'src/app/services/commons/geocoding.services';

import { LicenceType } from './../../models/licence.type.enum';
import { User } from 'src/app/models/user.model';
import { UserProfile } from 'src/app/models/user.profile.enum';

@Component({
  selector: 'app-userinformations',
  templateUrl: './userinformations.component.html',
  styleUrls: ['./userinformations.component.scss']
})
export class UserInformationsComponent {
  
  public isCellPhone: boolean = false;

  get LicenceType() {
    return LicenceType;
  }

  public user: User = new User();
  public confirmpassword: string = null;
  public passwordsNotEquals = false;
  public allFieldsRequired = false;
  public licenceType: number = 0;

  constructor(
    private router: Router,
    private renderer: Renderer2, 
    private elementRef: ElementRef,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private localStorageServices: LocalStorageServices,
    private settingsServices: SettingsServices,
    private emailsServices: EmailsServices,
    private stringsServices: StringsServices,
    private geocodingServices: GeocodingServices,
    private usersServices: UsersServices) { 
      this.activatedRoute.params.subscribe(params => {
        console.log('PaymentComponent.constructor : Retrieved params', params);
        this.licenceType = Number(params['licencetype']);
        if(this.licenceType !== LicenceType.PARTICULAR_MONTHLY && this.licenceType !== LicenceType.PROFESSIONAL_MONTHLY) {
          console.log('UserInformationsComponent.constructor : Licence type not managed', this.licenceType, typeof(this.licenceType));
          this.router.navigate(['/home']);
        }
      });
    }

  async ngOnInit(): Promise<void> {
    this.detectScreenWidth();
    await this.geocodingServices.getLocation()
    .then((locationData) => {
      console.log('UserInformationsComponent: Location data:', locationData);
      this.user.city = locationData.city;
      this.user.country = locationData.country;
    })
    .catch((error) => {
      console.error('UserInformationsComponent : Error:', error);
    });
  }

  public comparePasswords() {
    if(this.user.password !== this.confirmpassword) {
      this.passwordsNotEquals = true;
    } else {
      this.passwordsNotEquals = false;
    }
  }

  public async createAccount(): Promise<void> {
    this.comparePasswords();
    if(
      this.user.firstname !== undefined && this.user.firstname !== null && this.user.firstname !== '' &&
      this.user.lastname !== undefined && this.user.lastname !== null && this.user.lastname !== '' &&
      this.user.email !== undefined && this.user.email !== null && this.user.email !== '' &&
      this.user.city !== undefined && this.user.city !== null && this.user.city !== '' &&
      this.user.country !== undefined && this.user.country !== null && this.user.country !== '' &&
      this.user.password !== undefined && this.user.password !== null && this.user.password !== '' && this.user.password === this.confirmpassword
    ) {
      this.user.language = this.settingsServices.currentLanguage;
      this.user.mailcodeconfirmation = this.stringsServices.generateConfirmationEmailCode();
      this.allFieldsRequired = false;
      
      this.user.email = this.user.email.toLowerCase();
      this.user.profile = UserProfile.User;
      await this.usersServices.addUser(this.user).then((data: User) => {
        console.log('UserInformationsComponent.addUser : User added', data);
        this.localStorageServices.set('currentUser', data);
        this.user = data;
      }).catch(error => {
        console.error('UserInformationsComponent.addUser : Failed', error);
        if(error === 403) {
          this.snackBar.open(this.translateService.instant('userinformations.emailalreadyexists'), this.translateService.instant('generic.close'), {
            duration: 5000,
          });
          throw new Error('UsersServices.get : ' + error);
        } else if(error === 500) {
          this.snackBar.open(this.translateService.instant('generic.internalerror'), this.translateService.instant('generic.close'), {
            duration: 5000,
          });
          throw new Error('UsersServices.get : ' + error);
        }
      });

      const message = 'Here is your confirmation code to validate your GlobalVesta account : ' + this.user.mailcodeconfirmation;
        await this.emailsServices.send(this.user.email, message, 'Mail confirmation', 'template_k56120a').then(data => {
          console.log('UserInformationsComponent.addUser : Email sent', data);
        });

        this.snackBar.open(this.translateService.instant('userinformations.checkyourmails'), this.translateService.instant('generic.close'), {
          duration: 5000,
        });

        this.router.navigate(['/confirmemail', this.user._id, this.licenceType]);
    } else {
      this.allFieldsRequired = true;
    }
  }

  public connection(): void {
    console.debug('UserInformationsComponent.connection: Start to connect', this.user);
    this.user.email = this.user.email.toLowerCase();
    this.usersServices.authenticate(this.user.email, this.user.password).then((data: User) => {
        this.user = data;
        console.debug('UserInformationsComponent.connection: User connected', this.user);
        this.user.password = '**********';
        this.localStorageServices.set('currentUser', this.user);
        this.router.navigate(['/confirmemail', this.user._id, this.licenceType]);
    });
  }

  private detectScreenWidth() {
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    if (screenWidth <= 768) {
      // Le code à exécuter lorsque l'écran est de petite taille (téléphone portable)
      // Par exemple, vous pouvez ajouter ou supprimer des éléments DOM spécifiques
      // en fonction de la largeur de l'écran.
      this.isCellPhone = true;
      this.renderer.addClass(this.elementRef.nativeElement, 'mobile-screen');
    } else {
      // Le code à exécuter lorsque l'écran est de grande taille (desktop)
      // Par exemple, vous pouvez réinitialiser les modifications apportées pour les mobiles.
      this.renderer.removeClass(this.elementRef.nativeElement, 'mobile-screen');
    }
  }

}
