import { Entity } from "./entity.model";
import { UserProfile } from "./user.profile.enum";

export class User extends Entity {
  static readonly prefix_salt: string = 'gl0b4l_';
  static readonly suffix_salt: string = '_v3st4';

  firstname: string;
  lastname: string;
  email: string;
  password: string;
  mailcodeconfirmation: string;
  ismailactive: boolean;
  language: string;
  city: string;
  country: string;
  profile: UserProfile;
}
