import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, OnDestroy, Output } from '@angular/core';

import { User } from 'src/app/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class DataAccessServices implements OnDestroy {

  @Output() cacherefreshed: EventEmitter<boolean> = new EventEmitter();
  public iscacherefreshed: boolean = false;

  public currentUser: User = null;

  constructor(
    private httpClient: HttpClient,
    ) { }

  public ngOnDestroy(): void {
  }

  public async initialize(): Promise<boolean> {
    console.log('DataAccessServices.initialize: Start to initialize data');

    return new Promise<boolean>(async (resolve, reject) => {
      if (this.currentUser === null || this.currentUser === undefined) {
        reject(false);
      } else {
        resolve(true);
      }
    });
  }
}
