<mat-card class="popup-card flex">
  <mat-card-content class="scrollable">
    <div class="p-sm-4 p-2">
      <div class="row mb-3">
        <div class="col-12">
            <h2 class="tm-page-title">Our product</h2>        
        </div>
      </div>
      <div class="row mb-3">
          <div class="col-12">
              <p>VestaPedia is a platform for seamlessly merging supplier catalogs. Your essential tool for interior designers. Unlock inspiration, trends, and expert insights to elevate your designs and impress clients.</p>
              <p>Make your life easier for just $20 per month !</p>
          </div>
      </div>
      <div class="row">
          <div class="col-12">
              <a class="btn btn-primary tm-btn-submit" (click)="close()" [routerLink]="['/userinformations', LicenceType.PARTICULAR_MONTHLY]">Apply now</a>
          </div>
          <!--
          <div class="col-6">
              <a class="btn btn-primary tm-btn-submit" [routerLink]="['/userinformations', LicenceType.PROFESSIONAL_MONTHLY]">Apply now for professional use</a>
          </div>
          -->
      </div>
      <div class="row mt-5">
        <a class="btn btn-primary tm-btn-submit" (click)="close()">Close</a>
      </div>
    </div>
  </mat-card-content>
</mat-card>
