import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StripeServices {

  constructor(
    private httpClient: HttpClient) { }

    public async payment(cardNumber: string, expMonth: string, expYear: string, cvc: string, amount: string, currency: string, description: string): Promise<any> {
      console.log('StripeServices.payment: Set the payment');
      return new Promise<any>(async (resolve, reject) => {

        const bodyAuth = new URLSearchParams();
        bodyAuth.set('card[number]', cardNumber);
        bodyAuth.set('card[exp_month]', expMonth);
        bodyAuth.set('card[exp_year]', expYear);
        bodyAuth.set('card[cvc]', cvc);

        const headersAuth = new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': `Bearer ${environment.stripe.publicKey}`
        });

        let tokenId = null;
        let clientIp = null;
        await this.httpClient.post<any>(environment.stripe.authenticationUrl, bodyAuth.toString(), { headers: headersAuth }).toPromise().then((data: any) => {
          console.log('StripeServices.payment : Authentication finished', data);
          tokenId = data.id;
          clientIp = data.client_ip;
        });

        const bodyPayment = new URLSearchParams();
        bodyPayment.set('amount', amount);
        bodyPayment.set('currency', currency);
        bodyPayment.set('description', description);
        bodyPayment.set('source', tokenId);

        const headersPayment = new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': `Bearer ${environment.stripe.secretKey}`
        });

        await this.httpClient.post<any>(environment.stripe.paymentUrl, bodyPayment.toString(), { headers: headersPayment }).toPromise().then((data: any) => {
          console.log('StripeServices.payment : Payment set', data);
          resolve(data);
        });

      });
    } 
}
