import { Component } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { ProductsPopupComponent } from 'src/app/components/products-popup/products-popup.component';

import { LicenceType } from './../../models/licence.type.enum';
import { WelcomePopupComponent } from 'src/app/components/welcome-popup/welcome-popup.component';
import { TeamsPopupComponent } from 'src/app/components/teams-popup/teams-popup.component';
import { ContactPopupComponent } from 'src/app/components/contact-popup/contact-popup.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  get LicenceType() {
    return LicenceType;
  }

  public year: string = new Date().getFullYear().toString();

  constructor(private dialog: MatDialog) {}

  public openTile(tileName: string) {
    console.log('HomeComponent.openTile : Open the tile', tileName);

    let data: any = {};
    let dialogRef = null;
    if(tileName === 'products') {
      dialogRef = this.dialog.open(ProductsPopupComponent, {
        data: data
      });
    } else if(tileName === 'welcome') {
      dialogRef = this.dialog.open(WelcomePopupComponent, {
        data: data
      });
    } else if(tileName === 'teams') {
      dialogRef = this.dialog.open(TeamsPopupComponent, {
        data: data
      });
    } else if(tileName === 'contact') {
      dialogRef = this.dialog.open(ContactPopupComponent, {
        data: data
      });
    }

    dialogRef.afterClosed().subscribe(result => {
      console.log(`La fenêtre modale a été fermée avec le résultat : ${result}`);
    });
  }
}
