import { Component, Inject } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-teams-popup',
  templateUrl: './teams-popup.component.html',
  styleUrls: ['./teams-popup.component.scss']
})
export class TeamsPopupComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<TeamsPopupComponent>) {
    console.log('TeamsPopupComponent.constructor : Start to open the popup', data);
  }

  close(): void {
    this.dialogRef.close();
  }
}
