<mat-card class="popup-card flex">
    <mat-card-content class="scrollable">
      <div class="p-sm-4 p-2">
        <div class="row mb-3">
            <div class="col-12">
                <h2 class="tm-page-title">Welcome to GlobalVesta</h2>        
            </div>
        </div>                                        
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <img src="assets/images/welcome-1.jpg" class="img-fluid mb-3">
                <p>We believe that the best designs are born from collaboration and inspiration, and that's exactly what we aim to achieve here. Whether you're looking for inspiration for your next project or eager to share your expertise, this community is your canvas.</p>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <p>Hello and a warm welcome to the Interior Designers Hub – your virtual gathering place for all things interior design! Whether you're an experienced professional, an aspiring designer, or simply someone with a deep appreciation for aesthetics and creativity, you've found your home here. Our mission is to bring together passionate interior designers and enthusiasts, fostering a vibrant community of like-minded individuals.</p>
                <img src="assets/images/welcome-2.jpg" class="img-fluid" width="350px">
            </div>                                        
        </div>
        <div class="row mt-5">
          <a class="btn btn-primary tm-btn-submit" (click)="close()">Close</a>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  