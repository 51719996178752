import { Component, ElementRef, HostListener, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { LocalStorageServices } from 'src/app/services/commons/local.storage.services';
import { SettingsServices } from 'src/app/services/commons/settings.services';
import { EmailsServices } from 'src/app/services/emails/emails.services';
import { UsersServices } from 'src/app/services/users/users.services';
import { PaymentsServices } from 'src/app/services/payments/payments.services';

import { User } from 'src/app/models/user.model';
import { LicenceType } from 'src/app/models/licence.type.enum';
import { environment } from 'src/environments/environment';
import { Payment } from 'src/app/models/payment.model';
import { StripeServices } from 'src/app/services/payments/stripe.services';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent {
  
  get LicenceType() {
    return LicenceType;
  }
  
  public isCellPhone: boolean = false;
  public userId: string;
  public licenceType: number = 0;
  public user: User;

  private cardType: string;
  public cardNumber: string;
  public cardName: string;
  public cardExpireMonth: string;
  public cardExpireYear: string;
  public cardCCV: string;
  private defaultNumber: string;
  private defaultName: string;
  private ccCard: any;

  private payment: Payment = null;

  constructor(
    private router: Router,
    private renderer: Renderer2, 
    private elementRef: ElementRef,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private usersServices: UsersServices,
    private stripeServices: StripeServices,
    private paymentsServices: PaymentsServices) { 
      this.activatedRoute.params.subscribe(params => {
        console.log('ConfirmemailComponent.constructor : Retrieved params', params);
        this.licenceType = Number(params['licencetype']);
        this.userId = params['userid'];
        if(this.licenceType !== LicenceType.PARTICULAR_MONTHLY && this.licenceType !== LicenceType.PROFESSIONAL_MONTHLY) {
          console.log('ConfirmemailComponent.constructor : Licence type not managed', this.licenceType, typeof(this.licenceType));
          this.router.navigate(['/home']);
        }
      });
    }

    async ngOnInit() {
      console.log('PaymentComponent.ngOnInit : Licence type', this.licenceType);
      this.detectScreenWidth();

      this.usersServices.get(this.userId).then((data: User) => {
        console.log('PaymentComponent.ngOnInit : User retrieved', data);
        this.user = data;
      });

      this.initCard();
    }

    private initCard(): void {
      this.defaultNumber = 'XXXX XXXX XXXX XXXX'; // Mettez la valeur par défaut de votre choix ici
      this.defaultName = 'YOUR NAME';
    }

    ngAfterViewInit(): void {
      this.initPayment();
    }

    private initPayment() {
      const ccForm = document.getElementsByTagName('form')[1];
      const cCard = document.querySelector('#cc-card');
      const ccNumber = ccForm.querySelector('#cardnumber') as HTMLInputElement;
      const cNumber = document.querySelectorAll('.card-number');
      const ccName = ccForm.querySelector('#cardholder') as HTMLInputElement;
      const cName = document.querySelectorAll('.card-holder');
      const ccMonth = ccForm.querySelector('#expires-month') as HTMLInputElement;
      const cMonth = document.querySelectorAll('.e-month');
      const ccYear = ccForm.querySelector('#expires-year') as HTMLInputElement;
      const cYear = document.querySelectorAll('.e-year');
      const ccCCV = ccForm.querySelector('#ccv') as HTMLInputElement;
      this.ccCard = document.querySelectorAll('.credit-card-type');
      
      this.addEvent(ccName, 'focus', () => {
        cName[0].classList.add('glow');
      });
      this.addEvent(ccName, 'blur', () => {
        cName[0].classList.remove('glow');
      });
      this.addEvent(ccMonth, 'focus', () => {
        cMonth[0].classList.add('glow');
      });
      this.addEvent(ccMonth, 'blur', () => {
        cMonth[0].classList.remove('glow');
      });
      this.addEvent(ccYear, 'focus', () => {
        cYear[0].classList.add('glow');
      });
      this.addEvent(ccYear, 'blur', () => {
        cYear[0].classList.remove('glow');
      });
      this.addEvent(ccCCV, 'focus', () => {
        cCard.classList.add('flipped');
      });
      this.addEvent(ccCCV, 'blur', () => {
        cCard.classList.remove('flipped');
      });
      this.addEvent(ccNumber, 'focus', () => {
        cNumber[0].classList.add('glow');
      });
      this.addEvent(ccNumber, 'blur', () => {
        cNumber[0].classList.remove('glow');
      });
    }

    private numSplit(number, indexes) {
      var tempArr = number.split(''),
          parts = [];
      for (var i=0, l = indexes.length; i < l; i++) {
        if (tempArr.length) {
          parts.push(tempArr.splice(0,indexes[i]).join(''));   
        }
      }
      return parts;
    }

    public onCreditCardNumberChanged() {
      
      // Supprimez d'abord tous les espaces existants dans le numéro
      const strippedNumber = this.cardNumber.replace(/\s+/g, '');

      // Utilisez une regex pour ajouter des espaces entre les groupes de 4 chiffres
      const formattedNumber = strippedNumber.replace(/(\d{4})/g, '$1 ');

      // Retournez le numéro de carte formaté
      this.cardNumber = formattedNumber.trim(); // Pour supprimer l'espace final

      this.cardType = this.getCardType(this.cardNumber.replace(/\s/g,''));
      let parts;
      switch(this.cardType) {
        case 'amex':
          parts = this.numSplit(this.cardNumber.replace(/\s/g,''), [4,6,5]);
          this.ccCard[0].className = 'credit-card-type amex';
          break;
        case 'mastercard': 
          parts = this.numSplit(this.cardNumber.replace(/\s/g,''), [4,4,4,4]);
          this.ccCard[0].className = 'credit-card-type mastercard';
          break;
        case 'visa': 
          parts = this.numSplit(this.cardNumber.replace(/\s/g,''), [4,4,4,4]);
          this.ccCard[0].className = 'credit-card-type visa';
          break;
        default:
          parts = this.cardNumber.split(' ');
          this.ccCard[0].className = 'credit-card-type';
      }
    }

    public onCreditCardNameChanged() {
      this.cardName = this.cardName.replace(/[^a-zA-Z-\.\s]/g,'');
    }

    public onCreditCardExpireMonthChanged() {
      let month = parseInt(this.cardExpireMonth.replace(/[^0-9]/g, ''));
      if(month < 0) {
        this.cardExpireMonth = "1";
      }
      if(month > 12) {
        this.cardExpireMonth = "12";
      }
    }

    public onCreditCardExpireYearChanged() {
      const currentYear = new Date().getFullYear().toString().substr(2, 2);
      let year = parseInt(this.cardExpireYear.replace(/[^0-9]/g, ''));
      if(year < parseInt(currentYear) && this.cardExpireYear.length > 1) {
        this.cardExpireYear = currentYear;
      }
    }

    public onCreditCardCcvChanged() {
      this.cardCCV = this.cardCCV.replace(/[^0-9\s]/g,'');
    }

    public async processPayment() {
      console.log('PaymentComponent.makePayment : Start to make a payment');
      
      // Create a token when the form is submitted.
      const payment = new Payment();
      payment.amount = this.licenceType;
      payment.description = 'VestaPedia ' + this.translateService.instant('payment.particularmonthly');
      payment.userId = this.userId;

      await this.stripeServices.payment(this.cardNumber, this.cardExpireMonth, this.cardExpireYear, this.cardCCV, '1999', 'USD', payment.description).then(data => {
        console.log('PaymentComponent.processPayment : Payment set', data);
        payment.stripeToken = data.id;
        payment.status = data.status;
        payment.method = data.method;
      });

      await this.paymentsServices.addPayment(payment).then((data: Payment) => {
        console.log('PaymentComponent.processPayment : Payment added', data);
        this.payment = data;
      });
      
      this.router.navigate(['/bill', this.user._id, this.payment._id]);
    }
    
    private getCardType(number) {
      var re;
      // Mastercard
      re = new RegExp("^5[1-5]");
      if (number.match(re) != null) {
        return "mastercard";
      }
      // AMEX
      re = new RegExp("^3[47]");
      if (number.match(re) != null) {
          return "amex";
      }
      // visa
      re = new RegExp("^4");
      if (number.match(re) != null) {
        return "visa";
      }
  
      return "";
    }
  
    private addEvent(elem, event, func) {
      if(elem !== undefined && elem !== null) {
        elem.addEventListener(event,func);
      }
    }

    private detectScreenWidth() {
      const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  
      if (screenWidth <= 768) {
        // Le code à exécuter lorsque l'écran est de petite taille (téléphone portable)
        // Par exemple, vous pouvez ajouter ou supprimer des éléments DOM spécifiques
        // en fonction de la largeur de l'écran.
        this.isCellPhone = true;
        this.renderer.addClass(this.elementRef.nativeElement, 'mobile-screen');
      } else {
        // Le code à exécuter lorsque l'écran est de grande taille (desktop)
        // Par exemple, vous pouvez réinitialiser les modifications apportées pour les mobiles.
        this.renderer.removeClass(this.elementRef.nativeElement, 'mobile-screen');
      }
    }
}
