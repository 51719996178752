import { Component } from '@angular/core';

import { DataAccessServices } from './services/commons/data.access.services';
import { SettingsServices } from './services/commons/settings.services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    private settingsServices: SettingsServices,
    public dataAccessServices: DataAccessServices
  ) {
    this.settingsServices.initialize();
  }
}
