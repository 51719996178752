<div id="tm-bg"></div>
    <div id="tm-wrap">
        <div class="tm-main-content">
            <div class="container tm-site-header-container">
                <div class="row">
                    <div class="col-sm-12 col-md-4 col-lg-4 col-md-col-xl-4 mb-md-0 mb-sm-4 mb-4 tm-site-header-col">
                        <img src="assets/images/logo_gv.png" class="img-fluid mb-4">                  
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4 col-md-col-xl-4 mb-md-0 mb-sm-4 mb-4 tm-site-header-col">
                        <div class="tm-site-header">
                            <h1 class="mb-4" style="font-size:60px;">GlobalVesta</h1>
                            <img src="assets/images/underline.png" class="img-fluid mb-4">
                            <p style="background-color: rgba(0, 0, 0, 0.6); border: 1px solid white;padding:15px">Ready to revolutionize interior design with flair and passion? We're your trusted partner in bringing your boldest creations to life. Explore the art of transformation!</p>        
                        </div>                        
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <div class="content">
                            <div class="grid">
                                <div class="grid__item" id="home-link" (click)="openTile('welcome')">
                                    <div class="product">
                                        <div class="tm-nav-link">
                                            <i class="fas fa-home fa-3x tm-nav-icon"></i>
                                            <span class="tm-nav-text">Welcome</span>
                                            <div class="product__bg"></div>        
                                        </div>                                    
                                    </div>
                                </div>

                                <div class="grid__item" [routerLink]="['/userinformations', LicenceType.PARTICULAR_MONTHLY]">
                                    <div class="product">
                                        <div class="tm-nav-link">
                                            <i class="fas fa-credit-card fa-3x tm-nav-icon"></i>
                                            <span class="tm-nav-text">Apply now</span>
                                            <div class="product__bg"></div>        
                                        </div>                                    
                                    </div>
                                </div>

                                <div class="grid__item" id="team-link" (click)="openTile('teams')">
                                    <div class="product">
                                        <div class="tm-nav-link">
                                            <i class="fas fa-users fa-3x tm-nav-icon"></i>
                                            <span class="tm-nav-text">Our Team</span>
                                            <div class="product__bg"></div>            
                                        </div>                                     
                                    </div>
                                </div>

                                <div class="grid__item" (click)="openTile('products')">
                                    <div class="product">
                                        <div class="tm-nav-link">
                                            <i class="fas fa-book fa-3x tm-nav-icon"></i>
                                            <span class="tm-nav-text">Our Product</span>
                                            <div class="product__bg"></div>             
                                        </div>                                                                 
                                    </div>
                                </div>

                                <div class="grid__item" (click)="openTile('contact')">
                                    <div class="product">
                                        <div class="tm-nav-link">
                                            <i class="fas fa-comments fa-3x tm-nav-icon"></i>
                                            <span class="tm-nav-text">Contact</span>
                                            <div class="product__bg"></div>             
                                        </div>                                                              
                                    </div>
                                </div>
                            </div> 
                        </div>                       
                    </div>
                </div>                
            </div>
            <footer>
                <p class="small tm-copyright-text">Copyright &copy; <span class="tm-current-year">{{ year }}</span> GlobalVesta. 
            </footer>
        </div> <!-- .tm-main-content -->  
    </div>

    <script>      

        function setupFooter() {
            var pageHeight = $('.tm-site-header-container').height() + $('footer').height() + 100;

            var main = $('.tm-main-content');

            if($(window).height() < pageHeight) {
                main.addClass('tm-footer-relative');
            }
            else {
                main.removeClass('tm-footer-relative');   
            }
        }

        /* DOM is ready
        ------------------------------------------------*/
        $(function(){

            setupFooter();

            $(window).resize(function(){
                setupFooter();
            });        
        });

    </script>    