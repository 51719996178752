import { Component, Inject } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { EmailsServices } from 'src/app/services/emails/emails.services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contact-popup',
  templateUrl: './contact-popup.component.html',
  styleUrls: ['./contact-popup.component.scss']
})
export class ContactPopupComponent {

  public email: string;
  public wrongEmailMessage: boolean = false;
  public message: string;
  public wrongMessage: boolean = false;

  private emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ContactPopupComponent>,
    private emailsServices: EmailsServices) {
    console.log('ContactPopupComponent.constructor : Start to open the popup', data);
  }

  public async sendMessage(): Promise<void> {
    if(this.emailPattern.test(this.email) === false) {
      this.wrongEmailMessage = true;
    } else {
      if(this.message === undefined || this.message === null || this.message === '' || this.message === ' ') {
        this.wrongMessage = true;
      } else {
        const message = 'New message : ' + this.message + '. From : ' + this.email;
        await this.emailsServices.send(environment.globalvestaEmail, message, 'Contact', 'template_k56120a').then(data => {
          console.log('ContactPopupComponent.sendMessage : mail sent', data);
        });
        this.close();
      }
    }
  }

  public close(): void {
    this.dialogRef.close();
  }
}
