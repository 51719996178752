import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { EmailsServices } from 'src/app/services/emails/emails.services';
import { UsersServices } from 'src/app/services/users/users.services';

import { LicenceType } from 'src/app/models/licence.type.enum';
import { User } from 'src/app/models/user.model';

@Component({
  selector: 'app-confirmemail',
  templateUrl: './confirmemail.component.html',
  styleUrls: ['./confirmemail.component.scss']
})
export class ConfirmemailComponent {

  public isCellPhone: boolean = false;

  get LicenceType() {
    return LicenceType;
  }

  @ViewChild('inputDigit1', { static: false }) inputDigit1: ElementRef;
  @ViewChild('inputDigit2', { static: false }) inputDigit2: ElementRef;
  @ViewChild('inputDigit3', { static: false }) inputDigit3: ElementRef;
  @ViewChild('inputDigit4', { static: false }) inputDigit4: ElementRef;
  @ViewChild('inputDigit5', { static: false }) inputDigit5: ElementRef;
  @ViewChild('inputDigit6', { static: false }) inputDigit6: ElementRef;
  @ViewChild('buttonSubmit', { static: false }) buttonSubmit: ElementRef;

  public digit1: number;
  public digit2: number;
  public digit3: number;
  public digit4: number;
  public digit5: number;
  public digit6: number;
  public userId: string;
  public licenceType: number = 0;
  public user: User;
  
  constructor(
    private router: Router,
    private renderer: Renderer2, 
    private elementRef: ElementRef,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private emailsServices: EmailsServices,
    private usersServices: UsersServices) { 
      this.activatedRoute.params.subscribe(params => {
        console.log('ConfirmemailComponent.constructor : Retrieved params', params);
        this.licenceType = Number(params['licencetype']);
        this.userId = params['userid'];
        if(this.licenceType !== LicenceType.PARTICULAR_MONTHLY && this.licenceType !== LicenceType.PROFESSIONAL_MONTHLY) {
          console.log('ConfirmemailComponent.constructor : Licence type not managed', this.licenceType, typeof(this.licenceType));
          this.router.navigate(['/home']);
        }
      });
    }

    async ngOnInit(): Promise<void> {
      this.detectScreenWidth();
      await this.usersServices.get(this.userId).then((data: User) => {
        console.log('ConfirmemailComponent.validAccount : User retrieved', data);
        this.user = data;
      }).catch(error => {
        if(error === 500) {
          this.snackBar.open(this.translateService.instant('generic.internalerror'), this.translateService.instant('generic.close'), {
            duration: 5000,
          });
        }
      });
    }

    ngAfterViewInit() {
      this.inputDigit1.nativeElement.focus();
    }

    public checkDigit(inputElement: HTMLInputElement) {

      const inputElementId = inputElement.id;
      console.log('ID de l\'élément source :', inputElementId);
      if(inputElementId === 'inputDigit1') {
        this.inputDigit2.nativeElement.focus();
      } else if (inputElementId === 'inputDigit2') {
        this.inputDigit3.nativeElement.focus();
      } else if (inputElementId === 'inputDigit3') {
        this.inputDigit4.nativeElement.focus();
      } else if (inputElementId === 'inputDigit4') {
        this.inputDigit5.nativeElement.focus();
      } else if (inputElementId === 'inputDigit5') {
        this.inputDigit6.nativeElement.focus();
      } else if (inputElementId === 'inputDigit6') {
        this.buttonSubmit.nativeElement.focus();
      }
    }
  
    public async validAccount(): Promise<void> {
      const mailCodeConfirmation = `${this.digit1}${this.digit2}${this.digit3}${this.digit4}${this.digit5}${this.digit6}`;
      
      if(this.user.mailcodeconfirmation === mailCodeConfirmation) {
        this.user.ismailactive = true;
        await this.usersServices.updateUser(this.user).then((data: User) => {
          console.log('ConfirmemailComponent.validAccount : User updated', data);
          this.user = data;
        });

        const message = 'Your account is now confirmed. Thank you !';
        await this.emailsServices.send(this.user.email, message, 'Mail confirmed', 'template_k56120a').then(data => {
          console.log('ConfirmemailComponent.validAccount : Email sent', data);
        });

        this.snackBar.open(this.translateService.instant('confirmemail.accountconfirmed'), this.translateService.instant('generic.close'), {
          duration: 2000,
        });

        setTimeout(() => {
          this.snackBar.dismiss();
          this.router.navigate(['/payment', this.userId, this.licenceType]);
        }, 3000);
        
      } else {
        this.snackBar.open(this.translateService.instant('confirmemail.codeconfirmationnotmatch'), this.translateService.instant('generic.close'), {
          duration: 5000,
        });
        this.digit1 = null;
        this.digit2 = null;
        this.digit3 = null;
        this.digit4 = null;
        this.digit5 = null;
        this.digit6 = null;
      }
    }
    
    private detectScreenWidth() {
      const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  
      if (screenWidth <= 768) {
        // Le code à exécuter lorsque l'écran est de petite taille (téléphone portable)
        // Par exemple, vous pouvez ajouter ou supprimer des éléments DOM spécifiques
        // en fonction de la largeur de l'écran.
        this.isCellPhone = true;
        this.renderer.addClass(this.elementRef.nativeElement, 'mobile-screen');
      } else {
        // Le code à exécuter lorsque l'écran est de grande taille (desktop)
        // Par exemple, vous pouvez réinitialiser les modifications apportées pour les mobiles.
        this.renderer.removeClass(this.elementRef.nativeElement, 'mobile-screen');
      }
    }
}
