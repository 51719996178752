<div class="row form-group" *ngIf="isCellPhone === true">
  <div class="login-logo-cellphone d-flex justify-content-center">
    <img src="assets/images/logo_gv.png" alt="logo" class="login-logo-image-cellphone" />
  </div>
</div>

<div class="row form-group">
    <div class="col-md-5" *ngIf="isCellPhone === false">
      <div class="login-logo">
        <img src="assets/images/logo_gv.png" alt="logo" class="login-logo-image" />
      </div>
    </div>
    <div class="col-md-7">
        <section id="terms" class="about section-bg">
            <div class="container">
              <form>
                <div class="form-group mt-5">
                  <label for="typeDigits">{{ 'mailconfirmation.enterdigits' | translate }}</label>
                </div>
                <div class="form-group mt-5">
                  <div class="row">
                    <div class="col-1 text-center mt-2">
                      <input type="number" class="form-control text-center digitbox" id="inputDigit1" name="inputDigit1" #inputDigit1 [(ngModel)]="digit1" maxlength="1" (keyup)="checkDigit(inputDigit1)">
                    </div>
                    <div class="col-1 text-center mt-2" style="margin-left:20px">
                      <input type="number" class="form-control text-center digitbox" id="inputDigit2" name="inputDigit2" #inputDigit2 [(ngModel)]="digit2" maxlength="1" (keyup)="checkDigit(inputDigit2)">
                    </div>
                    <div class="col-1 text-center mt-2" style="margin-left:20px">
                      <input type="number" class="form-control text-center digitbox" id="inputDigit3" name="inputDigit3" #inputDigit3 [(ngModel)]="digit3" maxlength="1" (keyup)="checkDigit(inputDigit3)">
                    </div>
                    <div class="col-1 d-flex justify-content-center align-items-center mt-2" style="margin-left:20px; color:black">
                      <h1 class="m-0">-</h1>
                    </div>
                    <div class="col-1 text-center mt-2" style="margin-left:20px">
                      <input type="number" class="form-control text-center digitbox" id="inputDigit4" name="inputDigit4" #inputDigit4 [(ngModel)]="digit4" maxlength="1" (keyup)="checkDigit(inputDigit4)">
                    </div>
                    <div class="col-1 text-center mt-2" style="margin-left:20px">
                      <input type="number" class="form-control text-center digitbox" id="inputDigit5" name="inputDigit5" #inputDigit5 [(ngModel)]="digit5" maxlength="1" (keyup)="checkDigit(inputDigit5)">
                    </div>
                    <div class="col-1 text-center mt-2" style="margin-left:20px">
                      <input type="number" class="form-control text-center digitbox" id="inputDigit6" name="inputDigit6" #inputDigit6 [(ngModel)]="digit6" maxlength="1" (keyup)="checkDigit(inputDigit6)">
                    </div>
                  </div>
                </div>
                <div class="form-group mt-5">
                  <button type="submit" class="btn btn-primary" #buttonSubmit (click)="validAccount()">{{ 'userinformations.submit' | translate }}</button>
                </div>
              </form>
            </div>
          </section>
    </div>
  </div>