import { Injectable } from '@angular/core';

import * as emailjs from 'emailjs-com';
import { EmailJSResponseStatus } from 'emailjs-com';

import { User } from 'src/app/models/user.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmailsServices {

  constructor() { 
    emailjs.init(environment.emailJs.userId);
  }

  public async send(email: string, message: string, title: string, templateId: string, attachment?: any): Promise<EmailJSResponseStatus> {
    console.log('EmailsServices.send: Start to send mail', email);

    // Configurez vos paramètres de service de messagerie et de modèle Email.js
    const emailParams = {
      to_email: email,
      title: title,
      message: message
    };
    /*
    if(attachment !== undefined && attachment !== null) {
      const file = new Blob(['Contenu de la pièce jointe'], { type: 'application/pdf' }); // Remplacez par le contenu de votre pièce jointe
      const attachment = new File([file], 'piece_jointe.pdf');
      emailParams.attachment = attachment;
    }*/

    // Envoyez l'e-mail en utilisant Email.js
    return emailjs.send(environment.emailJs.serviceId, templateId, emailParams);
  }
}
