<mat-card class="popup-card flex">
    <mat-card-content class="scrollable">
        <div class="pt-sm-4 pb-sm-4 pl-sm-5 pr-sm-5 pt-2 pb-2 pl-3 pr-3">
            <div class="row mb-3">
                <div class="col-12">
                    <h2 class="tm-page-title">Contact Us</h2>        
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-12">
                    <p>Your satisfaction is our top priority! If you have any questions, specific needs, or are seeking inspiration, don't hesitate to contact us using the form below. Our dedicated team is ready to assist you in finding the perfect solution for your interior design needs. Get ready to transform your living space with us and create a unique experience that reflects your personal style. Fill out the form now to start your journey toward exceptional interior design!</p>
                </div>
            </div>
            <div class="row form-group mt-5">
                <mat-form-field class="full-width">
                    <mat-label>{{ 'home.youremail' | translate }}</mat-label>
                    <input matInput class="form-control-light" id="email" name="email" placeholder="{{ 'home.email' | translate }}" [(ngModel)]="email" maxlength="100">
                </mat-form-field>
            </div>
            <div class="row form-group mt-5">
                <mat-form-field class="full-width">
                    <mat-label>{{ 'home.yourmessage' | translate }}</mat-label>
                    <textarea matInput class="form-control-light" id="message" name="message" placeholder="{{ 'home.message' | translate }}" maxlength="1000" rows="5" [(ngModel)]="message"></textarea>
                  </mat-form-field>                  
            </div>
            <div class="row form-group mt-5">
                <a class="btn btn-primary tm-btn-submit" (click)="sendMessage()">Send</a>             
            </div>
            <div class="row mt-5">
                <a class="btn btn-primary tm-btn-submit" (click)="close()">Close</a>
            </div>
        </div>  
    </mat-card-content>
  </mat-card>