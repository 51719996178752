<div class="row form-group" *ngIf="isCellPhone === true">
  <div class="login-logo-cellphone d-flex justify-content-center">
    <img src="assets/images/logo_gv.png" alt="logo" class="login-logo-image-cellphone" />
  </div>
</div>

<div class="row form-group">
  <div class="col-md-5" *ngIf="isCellPhone === false">
    <div class="login-logo">
      <img src="assets/images/logo_gv.png" alt="logo" class="login-logo-image" />
    </div>
  </div>
  <div class="col-md-7">
    <section id="terms" class="about section-bg">
      <div class="container" style="color:black">
        <mat-tab-group>
          <mat-tab label="{{ 'userinformations.newaccount' | translate }}" class="custom-tab"> 
            <form>
              <div class="form-group mt-5">
                <mat-label>
                  <b style="color:black">{{ 'userinformations.youhavechooselicence' | translate }} : 
                    <span [ngSwitch]="licenceType">
                      <span *ngSwitchCase="1999">{{ 'userinformations.particularmonthly' | translate }}</span>
                      <span *ngSwitchCase="500">{{ 'userinformations.professionalmonthly' | translate }}</span>
                      <span *ngSwitchCase="5000">{{ 'userinformations.professionalyearly' | translate }}</span>
                    </span>
                  </b>
                </mat-label>
              </div>
              <div class="form-group mt-5">
                <mat-form-field class="full-width">
                  <mat-label>{{ 'userinformations.firstname' | translate }}</mat-label>
                  <input matInput class="form-control-light" id="exampleInputFirstname1" name="exampleInputFirstname1" placeholder="{{ 'userinformations.firstname' | translate }}" [(ngModel)]="user.firstname">
                </mat-form-field>
              </div>
              <div class="form-group mt-3">
                <mat-form-field class="full-width">
                  <mat-label>{{ 'userinformations.lastname' | translate }}</mat-label>
                  <input matInput class="form-control-light" id="exampleInputLastname1" name="exampleInputLastname1" placeholder="{{ 'userinformations.lastname' | translate }}" [(ngModel)]="user.lastname">
                </mat-form-field>
              </div>
              <div class="form-group mt-3">
                <mat-form-field class="full-width">
                  <mat-label>{{ 'userinformations.emailaddress' | translate }}</mat-label>
                  <input matInput class="form-control-light" id="exampleInputEmail1" name="exampleInputEmail1" placeholder="{{ 'userinformations.enteremail' | translate }}" [(ngModel)]="user.email">
                </mat-form-field>
                <small id="emailHelp" class="form-text text-muted"><i>{{ 'userinformations.nevershare' | translate }}</i></small>
              </div>
              <div class="form-group mt-3">
                <mat-form-field class="full-width">
                  <mat-label>{{ 'userinformations.password' | translate }}</mat-label>
                  <input type="password" matInput class="form-control-light" id="exampleInputPassword1" name="exampleInputPassword1" placeholder="{{ 'userinformations.password' | translate }}" [(ngModel)]="user.password">
                </mat-form-field>
                <small *ngIf="passwordsNotEquals" class="form-text text-muted"><i>{{ 'userinformations.passwordsnotequals' | translate }}</i></small> 
              </div>
              <div class="form-group mt-3">
                <mat-form-field class="full-width">
                  <mat-label>{{ 'userinformations.confirmpassword' | translate }}</mat-label>
                  <input type="password" matInput class="form-control-light" id="exampleInputPassword2" name="exampleInputPassword2" placeholder="{{ 'userinformations.confirmpassword' | translate }}" [(ngModel)]="confirmpassword" (change)="comparePasswords()">
                </mat-form-field>
                <small *ngIf="passwordsNotEquals" class="form-text text-muted"><i>{{ 'userinformations.passwordsnotequals' | translate }}</i></small>
              </div>
              <div class="form-group mt-3">
                <mat-form-field class="full-width">
                  <mat-label>{{ 'userinformations.city' | translate }}</mat-label>
                  <input matInput class="form-control-light" id="exampleInputCity" name="exampleInputCity" placeholder="{{ 'userinformations.city' | translate }}" [(ngModel)]="user.city">
                </mat-form-field>
              </div>
              <div class="form-group mt-3">
                <mat-form-field class="full-width">
                  <mat-label>{{ 'userinformations.country' | translate }}</mat-label>
                  <input matInput class="form-control-light" id="exampleInputCountry" name="exampleInputCountry" placeholder="{{ 'userinformations.country' | translate }}" [(ngModel)]="user.country">
                </mat-form-field>
              </div>
              <div *ngIf="allFieldsRequired" class="form-group mt-5">
                <small class="form-text text-muted"><i>{{ 'userinformations.allfieldsrequired' | translate }}</i></small>
              </div>
              <div class="form-group mt-5">
                <button type="submit" class="btn btn-primary" (click)="createAccount()">{{ 'userinformations.submit' | translate }}</button>
              </div>
            </form>
          </mat-tab>
          <mat-tab label="{{ 'userinformations.connection' | translate }}">
            <form>
              <div class="form-group mt-3">
                <mat-form-field class="full-width">
                  <mat-label>{{ 'userinformations.emailaddress' | translate }}</mat-label>
                  <input matInput type="email" class="form-control-light" id="exampleInputEmail2" name="exampleInputEmail2" placeholder="{{ 'userinformations.enteremail' | translate }}" [(ngModel)]="user.email">
                </mat-form-field>
                <small id="emailHelp" class="form-text text-muted"><i>{{ 'userinformations.nevershare' | translate }}</i></small>
              </div>
              <div class="form-group mt-3">
                <mat-form-field class="full-width">
                  <mat-label>{{ 'userinformations.password' | translate }}</mat-label>
                  <input matInput type="password" class="form-control-light" id="exampleInputPassword1" name="exampleInputPassword1" placeholder="{{ 'userinformations.password' | translate }}" [(ngModel)]="user.password">
                </mat-form-field>
                <small *ngIf="passwordsNotEquals" class="form-text text-muted"><i>{{ 'userinformations.passwordsnotequals' | translate }}</i></small>
              </div>
              <button type="submit" class="btn btn-primary mt-5" (click)="connection()">{{ 'userinformations.connection' | translate }}</button>
            </form>
          </mat-tab>
        </mat-tab-group>
      </div>
    </section>
  </div>
</div>




